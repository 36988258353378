import axios from "axios";
const API_PREFIX = process.env.REACT_APP_API_PREFIX;
export const getEntityCount = async (param, extra = "") => {
  let filter =
    "?" +
    extra +
    (param
      ? Object.keys(param)
          .filter((key) => param[key] !== "")
          .map(function (key) {
            return (
              encodeURIComponent(key) + "=" + encodeURIComponent(param[key])
            );
          })
          .join("&")
      : "");
  return await axios
    .get(`${API_PREFIX}/common/entity-count${filter}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};

export const sendCredentials = async ({exam_id,exam_days_id}) => {
  return await axios
    .post(`${API_PREFIX}/common/send-sms`,{exam_id:exam_id,exam_days_id:exam_days_id})
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};

export const sendCredentialsToCC = async (cc_id) => {
  return await axios
    .post(`${API_PREFIX}/common/send-sms-cc`,{cc_id:cc_id})
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
