import React, { useEffect, useState } from "react";
import { Cross, RINotificationFill } from "../../components/Icons";
import { formatDateTime } from "../../utils/DateFormat";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { dashboardUpdate, toggleShouldUpdate } from "../../store/slices/sideBar/sidebarCounterSlice";
import { handleApiError } from "../../common/errorHandler";
import * as NotifApi from "../../api/NotifApi";

export default function ImpNotification({ data }) {
  const dispatch = useDispatch();

  const handleDashboard_SidebarUpdate = () => {
    dispatch(toggleShouldUpdate());
    dispatch(dashboardUpdate());
  };

  const readNotification = async (payload) => {
    const dataPayload = {
      read_all: false,
      notification_id: payload,
    };
    try {
      const response = await NotifApi.readNotif(dataPayload);
      if (response?.status === 200) {
        handleDashboard_SidebarUpdate();
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  const popOver = (
    <Popover style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)", fontWeight: "600" }}>
      <Popover.Body>Read and Close</Popover.Body>
    </Popover>
  );

  return (
    <>
      {data.length > 0 ? (
        data?.map((item, index) => (
          <div className="d-flex gap-3  notification-container" key={index}>
            <div className="justify-content-center align-items-center">
              <div className="notification-icon">
                <RINotificationFill fill="white" height={60} width={40} style={{ marginLeft: "10px" }} />
              </div>
            </div>

            <div className="flex-grow-1">
              <div className="d-flex justify-content-between notification-header">
                <span className="notification-title">{item.title}</span>
                <OverlayTrigger placement="top" trigger="hover" overlay={popOver}>
                  <button onClick={() => readNotification(item.id)} className="notification-close-btn">
                    <Cross />
                  </button>
                </OverlayTrigger>
              </div>

              <p className="notification-description">{item.description}</p>

              <div className="d-flex justify-content-end notification-footer">
                <div className="d-flex justify-content-center align-items-center notification-sender">
                  <span className="fw-sm mx-1">
                    {item.sentBy.first_name} {item.sentBy.last_name} ({item.sentBy.Role.name}),
                  </span>
                </div>
                <div className="notification-date">{formatDateTime(item.created_at).replace(",","")}</div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className="text-center text-muted fw-medium">No more data</p>
      )}
    </>
  );
}
