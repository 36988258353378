import React, { useMemo, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import {
  RIconCamera,
  RIconDocument,
  RIconPC,
  RIconPolice,
  RIconUser,
  RIconWarning,
  RIconWatch,
} from "../Icons";
import { RIconRRB } from "../Icons/RIconRRB";
const incidentCategories = [
  {
    Time: <RIconWatch className="icon icon-md" />,
    Center: <RIconPC className="icon icon-md" />,
    CCTV: <RIconCamera className="icon icon-md" />,
    RRB: <RIconRRB className="icon icon-md" />,
    ECA: <RIconUser className="icon icon-md" />,
    Documents: <RIconDocument className="icon icon-md" />,
    Police: <RIconPolice className="icon icon-md" />,
    Malpractice: <RIconWarning className="icon icon-md" />,
  },
];
  const IncidentCard = ({ title, data, lg = false }) => {
  const [selectedDay, setSelectedDay] = useState("all");
  const sortedData = useMemo(() => {
    return [...data].sort((a, b) => a.exam_day_id - b.exam_day_id);
  }, [data]);
  // Calculate total incidents based on selected day
  const totalIncidents = sortedData.reduce((total, dayData) => {
    if (
      selectedDay === "all" ||
      dayData.exam_day_id === parseInt(selectedDay)
    ) {
      return (
        total +
        Object.values(dayData.incidentCountsByType).reduce(
          (sum, count) => sum + count,
          0
        )
      );
    }
    return total;
  }, 0);
  // Calculate category-wise incidents
  const categoryCounts = Object.keys(incidentCategories[0]).reduce(
    (result, category) => {
      result[category] = sortedData.reduce((total, dayData) => {
        if (
          selectedDay === "all" ||
          dayData.exam_day_id === parseInt(selectedDay)
        ) {
          return total + (dayData.incidentCountsByType[category] || 0);
        }
        return total;
      }, 0);
      return result;
    },
    {}
  );
  return (
    <Card className="">
      <Card.Header className="bg-light-rrb">
        <h3 className="fs-16 mb-0 fw-medium">
          {title} ({totalIncidents})
        </h3>
        <select
          className="ms-auto select-header"
          onChange={(e) => setSelectedDay(e.target.value)}
        >
          <option value="all">All Day</option>
          {sortedData?.map((item) => (
            <option key={item.exam_day_id} value={item.exam_day_id}>
              {item.date}
            </option>
          ))}
        </select>
      </Card.Header>
      <Card.Body className="p-3">
        <Row className="row-cards align-items-center justify-content-center text-center h-100">
          {Object.entries(incidentCategories[0]).map(([key, Icon]) => (
            <Col key={key} xs="4" md="3" lg={lg}>
              <div
                className={`${
                  categoryCounts[key] > 0 ? "text-danger" : "text-light-rrb"
                }  mb-1`}
              >
                {Icon}
              </div>
              <p className="text-secondary mb-0 text-nowrap">
                {key}{" "}
                {categoryCounts[key] > 0 ? `${`(${categoryCounts[key]})`}` : ""}
              </p>
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default IncidentCard;
