import React, { useEffect, useState } from "react";
import { Card, Col, OverlayTrigger, Popover, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import FunnelChart from "../../../components/Charts/FunnelChart";

const RRBContacts = ({ data = {}, className = "" }) => {
  const [countData, setCountData] = useState({
    rrb: 0,
    div: 0,
    rcArc: 0,
    center: 0,
    cc: 0,
  });
  const [funnelData, setFunnelData] = useState(null);

  console.log("data------>", data);

  useEffect(() => {
    let totalCC = 0;
    let totalRcArc = 0;

    data?.rrbs?.forEach((rrb) => {
      totalCC += rrb.cc?.length || 0;
      totalRcArc += rrb.rc?.length || 0;
    });
    setCountData((prevData) => ({
      ...prevData,
      rrb: data?.rrbs?.length || 0,
      cc: totalCC,
      rcArc: totalRcArc,
      center: data?.centers,
      div: data?.divisionCount,
    }));
  }, [data]);

  useEffect(() => {
    setFunnelData({
      categories: [
        `RRB ${countData.rrb}`,
        `DIV ${countData.div}`,
        `RC / ARC ${countData.rcArc}`,
        `CENTERS ${countData.center}`,
        `COORDINATORS ${countData.cc}`,
      ],
      colors: ["#2F2F8F", "#FF7F3F", "#f59f00", "#6A1B9A", "#2C9A32"],
      series: [
        {
          name: "",
          data: [24, 48, 72, 96, 120],
        },
      ],
    });
  }, [countData]);

  const renderUserList = (users = []) => {
    return (
      <div>
        {users.map((user) => (
          <div key={user.id} className="d-flex align-items-center my-1">
            <div className="avatar avatar-xs fs-4 avatar-rounded bg-primary text-white me-2 flex-shrink-0">
              {user.first_name?.[0]?.toUpperCase() || "?"}
            </div>
            <div>
              {user.first_name} {user.last_name} /{" "}
              <a target="_blank" href={`https://wa.me/${user.mobile}`} rel="noreferrer" className="text-dark text-underline">
                {user.mobile}
              </a>
            </div>
          </div>
        ))}
      </div>
    );
  };
  const renderUserPopover = (users, title = "User Lists") => (
    <Popover>
      <Popover.Header as="h3">{title}</Popover.Header>
      <Popover.Body className="overflow-y-auto py-2" style={{ maxHeight: "400px" }}>
        {renderUserList(users)}
      </Popover.Body>
    </Popover>
  );
  return (
    <Card className={className}>
      <Card.Header className="bg-light-rrb">
        <h3 className="fs-16 mb-0 fw-medium">RRB Contacts</h3>
      </Card.Header>
      <Card.Body className="p-0">
        <Row className="align-items-center">
          <Col md={3} className="">
            {funnelData && <FunnelChart data={funnelData} />}
          </Col>
          <Col className="ps-md-0 border-start">
            <Table responsive bordered className="card-table table-vcenter">
              <thead>
                <tr>
                  <th>RRB</th>
                  <th>Chairman</th>
                  <th>Secretaries (Member, Dy, Ad)</th>
                  <th>RC / ARC</th>
                  <th>Center Coordinators</th>
                  <th>Dashboard</th>
                </tr>
              </thead>
              <tbody>
                {data?.rrbs?.map((row, idx) => {
                  //   const chairmanName = `${row?.chairman?.first_name} ${row?.chairman?.last_name} / ${row?.chairman?.mobile}`;
                  return (
                    <tr key={idx}>
                      <td>{row?.rrb_name}</td>
                      <td>{renderUserList([row.chairman])}</td>
                      <td>{renderUserList(row?.secretaries)}</td>
                      <td>
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement="left"
                          overlay={renderUserPopover(row?.rc, "RC / ARC")}
                        >
                          <span role="button" className=" text-underline">
                            {row?.rc?.length}
                          </span>
                        </OverlayTrigger>
                      </td>
                      <td>
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement="left"
                          overlay={renderUserPopover(row?.cc, "Center Coordinators")}
                        >
                          <span role="button" className=" text-underline">
                            {row?.cc?.length}
                          </span>
                        </OverlayTrigger>
                      </td>
                      <td>
                        <Link
                          to={`/rrb-dashboards?id=${row?.rrb_id}`}
                          className="btn btn-sm btn-outline-primary bg-light-rrb border"
                        >
                          Visit Dashboard
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default RRBContacts;
