import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import RadialBarChart from "../../../components/Charts/RadialBarChart";
import ReportingStackBar from "../../../components/Charts/ReportingStackBar";

const ReportingProgress = ({ data = {}, className = "" }) => {
  return (
    <Card className={className}>
      <Card.Header className="bg-light-rrb">
        <h3 className="fs-16 mb-0 fw-medium">
          Reporting Progress
        </h3>
      </Card.Header>
      <Card.Body className="p-0">
        <Row>
          <Col md={3} className="border-end">
            <RadialBarChart
              title="Submitted Reports"
              data={data?.submitRatio || 0}
            />
          </Col>
          <Col>
            {data?.rrbWiseReporting && (
              <ReportingStackBar data={data?.rrbWiseReporting} />
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ReportingProgress;
