import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import ReportedIncidentsChart from "../../../components/IncidentsCharts/ReportedIncidentsChart";
import CategoryWiseIncidents from "../../../components/IncidentsCharts/CategoryWiseIncidents";
import DateWiseIncidents from "../../../components/IncidentsCharts/DateWiseIncidents";
import TopCentersIncidents from "../../../components/IncidentsCharts/TopCentersIncidents";


const IncidentAnalytics = ({ data = false, className = "" }) => {
  return (
    <Card className={className}>
      <Card.Header className="bg-light-rrb">
        <h3 className="fs-16 mb-0 fw-medium">Incident Analytics</h3>
      </Card.Header>
      <Card.Body className="p-0">
        <Row>
          <Col md={3} lg="3" className="border-end">
            <div className="py-2 px-3">
              <Card className="bg-danger-lt-rrb">
                <Card.Body className="text-center">
                  <div className="fw-bold fs-1 py-2 text-danger">{data.rrbWiseIncidents.data.reduce((sum, value) => sum + value, 0)}</div>
                  <div>Reported Incidents</div>
                </Card.Body>
              </Card>
              <ReportedIncidentsChart data={data?.rrbWiseIncidents}/>
            </div>
          </Col>
          <Col md={3} lg="3" className="border-end">
            <div className="py-3 px-3">
              <span className="fw-bold">Category Wise Incidents</span>
              <div className="mt-6">
                <CategoryWiseIncidents data={data?.categoryWiseIncidents}/>
              </div>
            </div>
          </Col>
          <Col md={3} lg="3" className="border-end">
            <div className="py-3 px-3">
              <span className="fw-bold">Date Wise Incidents</span>
              <div className="mt-6">
                <DateWiseIncidents data={data?.dateWiseIncidents} />
              </div>
            </div>
          </Col>
          <Col md={3} lg="3" className="border-end">
            <div className="py-3 px-3">
              <span className="fw-bold">Top 10 Centers Incidents</span>
            </div>
            <div className="mt-6">
                <TopCentersIncidents data={data?.centerWiseIncidents}/>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default IncidentAnalytics;
