import React, { useEffect, useState } from "react";

import StatusCapsual from "../../components/DataTable/StatusCapsule";
import { Badge, Card, Col, Row } from "react-bootstrap";
import DateBox from "../../components/General/DateBox";
const bgColor = {
  Completed: "success",
  "In Progress": "warning",
  Upcoming: "purple",
};
const DateCard = ({
  exam,
  carBgColor = "#fff",
  onClick,
  isExamReport = false,
  selectedDay = false,
}) => {
  const [examDate, setExamDate] = useState({});

  const formatDate = (dateString) => {
    const optionsDay = { day: "numeric" };
    const optionsMonth = { month: "short" };
    const optionsYear = { year: "numeric" };

    const date = new Date(dateString);
    const day = new Intl.DateTimeFormat("en-US", optionsDay).format(date);
    const month = new Intl.DateTimeFormat("en-US", optionsMonth)
      .format(date)
      .toUpperCase();
    const year = new Intl.DateTimeFormat("en-US", optionsYear).format(date);

    const monthYear = `${month}'${year}`;

    return setExamDate({ day, monthYear });
  };

  useEffect(() => {
    if (exam?.date) {
      formatDate(exam?.date);
    }
  }, [exam]);
  let cardClassName,
    cardTextClassName,
    cardTextSpanClassName,
    statusBadgeClass = "";
  if (!isExamReport) {
    cardClassName = `bg-${bgColor[`${exam?.exam_status}`]}-lt-rrb`;
    cardTextClassName = "text-secondary";
    cardTextSpanClassName = "text-black";
    statusBadgeClass = `border bg-white text-${
      bgColor[`${exam?.exam_status}`]
    }`;
  } else if (selectedDay === exam.id) {
    cardClassName = `bg-primary`;
    cardTextClassName = "text-white";
    cardTextSpanClassName = "text-white";
    statusBadgeClass = `bg-${bgColor[`${exam?.exam_status}`]}`;
  } else {
    cardTextClassName = "text-secondary";
    cardTextSpanClassName = "text-black";
    statusBadgeClass = `border bg-${bgColor[`${exam?.exam_status}`]}-lt`;
  }
  return (
    <Col xs={12} sm={6} xl={4} xxl={3}>
      <Card
        className={`${cardClassName} card-link`}
        onClick={onClick}
        role="button"
      >
        <Card.Body className="p-3">
          <Row className="align-items-center">
            <Col className="col-auto">
              <DateBox
                date={exam.date}
                className={`${!isExamReport ? "bg-white" : ""}`}
              />
            </Col>
            <Col className={cardTextClassName}>
              <Card.Text className={`mb-1 fs-3 text-truncate ${cardTextClassName}`}>
                <span className={`${cardTextSpanClassName} fw-semibold`}>
                  {exam.centers}
                </span>{" "}
                Centers
              </Card.Text>
              <Card.Text className={`mb-0 fs-3 text-truncate ${cardTextClassName}`}>
                {exam?.coordinators ? (
                  <>
                    <span className={`${cardTextSpanClassName} fw-semibold`}>
                      {exam.coordinators}
                    </span>{" "}
                    Coordinators
                  </>
                ) : (
                  <>
                    <span
                      className={`${
                        exam.incidents > 0 ? "text-danger" : "text-black"
                      } fw-semibold`}
                    >
                      {exam.incidents}
                    </span>{" "}
                    Incidents
                  </>
                )}
              </Card.Text>
            </Col>
            <Col className="col-auto">
              <Badge className={`${statusBadgeClass}`}>
                {exam?.exam_status}
              </Badge>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default DateCard;
