import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: 0,
  first_name: "Demo",
  last_name: "User",
  email: "demo@user.com",
  mobile: "",
  username: "demo",
  avatar_url: "",
  auth: false,
  role: { id: "", name: "Center Coordinator", slug: "center-coordinator" },
  tokens: {},
  rrb: {},
};

export const authUserSlice = createSlice({
  name: "authUser",
  initialState,
  reducers: {
    addAuthData: (state, action) => {
      state.id = action.payload.id;
      state.first_name = action.payload.first_name;
      state.last_name = action.payload.last_name;
      state.email = action.payload.email;
      state.mobile = action.payload.mobile;
      state.avatar_url = action.payload.avatar_url;
      state.auth = action.payload.auth;
      state.role = action.payload.role;
      state.tokens = action.payload.tokens;
      state.rrb = action.payload.rrb;
    },

    resetAuthData: (state, action) => {
      state.id = 0;
      state.first_name = "";
      state.last_name = "";
      state.email = "";
      state.mobile = "";
      state.avatar_url = "";
      state.auth = false;
      state.role = {};
      state.tokens = "";
      state.rrb = "";
    },
  },
});

export const { addAuthData, resetAuthData } = authUserSlice.actions;

export default authUserSlice.reducer;
