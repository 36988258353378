import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
const DateWiseIncidents = ({data}) => {
    const [state, setState] = React.useState(null);
    useEffect(() => {
      setState({
        series: [{
          name: "Incidents",
          data: data?.data
        }],
        
        options: {
          chart: {
            type: 'area',
            height: 350,
            zoom: {
              enabled: false
            },
            
            toolbar: {
              show: false, // Disable the toolbar
              tools: {
                download: false, // Disable the download option
              }
            }
          },
          legend: {
            show: false, // Turn off the legend
          },
          
          colors: ['#ED1D24'],
          dataLabels: {
            enabled: true,
          },
          labels: data?.categories,
          xaxis: {
            // type: 'datetime',
            labels: {
              formatter: function(value) {
                // Use moment to format the date in DD/MM
                return moment(value).format('DD/MM');
              }
            }
          },
          yaxis: {
            opposite: true,
            labels: {
              show: false
            }
          },
        },
      })
    }, [data]);
  return (
    <>
      {state && <ReactApexChart options={state.options} series={state.series} type="area" height={200} />}
      
    </>
  );
};
export default DateWiseIncidents;