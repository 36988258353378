import axios from "axios";
const API_PREFIX = process.env.REACT_APP_API_PREFIX;

export const getAllSendNotif = async (filter) => {
    return await axios
        .get(`${API_PREFIX}/notifications/sent?_page=${filter._page}&_limit=${filter._limit}&_sort=id&_order=desc&q=${filter.q}`)
        .then((res) => {
            return res.data;
        })
        .catch((error) => Promise.reject(error?.response.data));
};
export const getAllReceivedNotif = async (filter) => {
    return await axios
        .get(`${API_PREFIX}/notifications?_page=${filter._page}&_limit=${filter._limit}&_sort=id&_order=desc&q=${filter.q}`)
        .then((res) => {
            return res.data;
        })
        .catch((error) => Promise.reject(error?.response.data));
};
export const remove = async (id) => {
    return await axios
        .delete(`${API_PREFIX}/notifications/${id}`)
        .then((res) => {
            return res.data;
        })
        .catch((error) => Promise.reject(error?.response.data));
};
export const update = async (id, payload) => {
    return await axios
        .put(`${API_PREFIX}/notifications/${id}`, payload)
        .then((res) => {
            return res.data;
        })
        .catch((error) => Promise.reject(error?.response.data));
}; 
export const create = async (payload) => {
    return await axios
        .post(`${API_PREFIX}/notifications/add`, payload)
        .then((res) => {
            return res.data;
        })
        .catch((error) => Promise.reject(error?.response.data));
}; 
export const getOne = async (id, city_id) => {
    return await axios
        .get(`${API_PREFIX}/notifications/get_divisions_centers/${id}/${city_id}`)
        .then((res) => {
            return res.data;
        })
        .catch((error) => Promise.reject(error?.response.data));
};

export const getSendToOptions = async () => {
    return await axios
        .get(`${API_PREFIX}/notifications/roles`)
        .then((res) => {
            return res.data;
        })
        .catch((error) => Promise.reject(error?.response.data));
};

export const readNotif = async (payload) => {
    return await axios
        .put(`${API_PREFIX}/notifications/read`, payload)
        .then((res) => {
            return res.data;
        })
        .catch((error) => Promise.reject(error?.response.data));
}; 

export const getUnReadNotifCount = async () => {
    return await axios
        .get(`${API_PREFIX}/notifications/unread`)
        .then((res) => {
            return res.data;
        })
        .catch((error) => Promise.reject(error?.response.data));
};