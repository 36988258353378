import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import RadialBarCircleChart from "../../../components/Charts/RadialBarCircleChart";
import AttendanceBarChart from "../../../components/Charts/AttendanceBarChart";

const AttendanceProgress = ({ data = false, className = "" }) => {
  const {
    scheduledCandidates = 0,
    appearedCandidates = 0,
    adhaarCount = 0,
    biometricsCount = 0,
    rrbWiseAttendance = {},
  } = data || {};
  const [radialChartData, setRadialChartData] = useState(false);
  const [rrbsAttendance, setRrbsAttendance] = useState(false);
  useEffect(() => {
    setRadialChartData({
      labels: ["Scheduled", "Appeared", "Adhaar", "Biometrics"],
      //   series: [76, 67, 61, 90],
      series: [
        scheduledCandidates,
        appearedCandidates,
        adhaarCount,
        biometricsCount,
      ],
      colors: ["#A5A5A5", "var(--tblr-success)", "var(--tblr-warning)", "var(--tblr-primary)"],
    });
    setRrbsAttendance({
      categories: rrbWiseAttendance?.categories || [],
      series: [
        {
          name: "Scheduled",
          data: rrbWiseAttendance?.scheduledCandidates,
          color: `#A5A5A5`,
        },
        {
          name: "Appeared",
          data: rrbWiseAttendance?.appearedCandidates,
          color: `var(--tblr-success)`,
        },
        {
          name: "Adhaar",
          data: rrbWiseAttendance?.adhaarCount,
          color: `var(--tblr-warning)`,
        },
        {
          name: "Biometrics",
          data: rrbWiseAttendance?.biometricsCount,
          color: `var(--tblr-primary)`,
        },
      ],
    });
  }, [data]);
  if (!data) return;
  return (
    <Card className={className}>
      <Card.Header className="bg-light-rrb">
        <h3 className="fs-16 mb-0 fw-medium">Attendance Progress</h3>
      </Card.Header>
      <Card.Body className="p-0">
        <Row>
          <Col md={3} className="border-end">
            {radialChartData && <RadialBarCircleChart data={radialChartData} />}
          </Col>
          <Col>
            {rrbsAttendance && <AttendanceBarChart data={rrbsAttendance} />}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default AttendanceProgress;
