import { Card, Col, Row } from "react-bootstrap";

export const StatCard = ({
  icon,
  bgClass,
  title,
  subtitle,
  colSize = "md-6 col-xl-3",
  isCardBg=false,
  className="",
  fontSizeClass="",
  colClass = ""

}) => {
  return (
    <Col className={colSize}>
      <Card className={`${isCardBg?`${bgClass}-lt-rrb`:""}`}>
        <Card.Body className={`p-3 ${className}`}>
          <Row className="align-items-center">
            <Col xs="auto" className={`${colClass}`}>
              <div className={`avatar avatar-md ${bgClass} text-white`}>{icon}</div>
            </Col>
            <Col className="text-dark">
              <Card.Text as="div" className={`mb-0 fw-medium ${fontSizeClass || "fs-30"} `}>
                {title || 0}
              </Card.Text>
              <Card.Text className="mb-0 text-truncate">{subtitle}</Card.Text>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};
export const ExamStatCard = ({
  icon,
  bgClass,
  title,
  subtitle,
  colSize = "md-6 col-xl-3",
}) => {
  return (
    <Col className={colSize}>
      <Card>
        <Card.Body className="p-3">
          <Row className="align-items-center">
            <Col xs="auto">
              <div className={`avatar avatar-md ${bgClass} text-white`}>{icon}</div>
            </Col>
            <Col>
              <Card.Text as="div" className="mb-0 fw-medium fs-30">
                {title || 0}
              </Card.Text>
              <Card.Text className="mb-0">{subtitle}</Card.Text>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};

