import React, { Fragment } from "react";
import { Row } from "react-bootstrap";
import { StatCard } from "./StatCard";
import {
  RIconFile,
  RIconPaperPlane,
  RIconPC,
  RIconPerson,
  RIconWarning,
} from "../../components/Icons";
import { RIconRRB } from "../../components/Icons/RIconRRB";

const CountWidget = ({ data = false,isSuperadmin=false }) => {
  if (!data) return;
  return (
    <Fragment>
      <StatCard
        icon={<RIconRRB />}
        bgClass="bg-cyan"
        title={(isSuperadmin)?data?.totalRrbs:data.totalDivisions}
        subtitle={`${isSuperadmin?"RRBs":"Divisions"}`}
        colSize="col-6 col-md-4 col-xxl"
      />
      <StatCard
        icon={<RIconPC />}
        bgClass="bg-warning"
        title={data?.centers}
        subtitle="Exams Centers"
        colSize="col-6 col-md-4 col-xxl"
      />
      <StatCard
        icon={<RIconPerson />}
        bgClass="bg-yellow"
        title={data?.centerCoordinators}
        subtitle="Center Coordinators"
        colSize="col-6 col-md-4 col-xxl"
      />
      <StatCard
        icon={<RIconFile />}
        bgClass="bg-purple"
        title={data?.totalExams}
        subtitle="Exams"
        colSize="col-6 col-md-4 col-xxl"
      />
      <StatCard
        icon={<RIconPaperPlane width={30} height={28.73} />}
        bgClass="bg-success"
        title={data?.reportsSubmitted}
        subtitle="Reports Submitted"
        colSize="col-6 col-md-4 col-xxl"
      />
      
      <StatCard
        icon={<RIconWarning />}
        bgClass="bg-danger"
        title={data?.reportedIncidents}
        subtitle="Reported Incidents"
        colSize="col-6 col-md-4 col-xxl"
      />
    </Fragment>
  );
};

export default CountWidget;