import {
  Card,
  Col,
  Row,
  Table,
} from "react-bootstrap";

const RRBExamSchedule = ({ data = [], className = "" }) => {
  const currentDate = new Date(); // Get current date
  const currentYear = currentDate.getFullYear(); // Get current year

  return (
    <Card className={className}>
      <Card.Header className="bg-light-rrb">
        <h3 className="fs-16 mb-0 fw-medium">Exam Schedule (Date/Centers)</h3>
      </Card.Header>
      <Card.Body className="p-0">
        <Row className="align-items-center">
          <Col>
            <Table responsive bordered className="card-table table-vcenter">
              <thead>
                <tr>
                  <th>RRB</th>
                  {data && data.header?.map((entry, dateIdx) => (
                    <th key={dateIdx}>{entry}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data?.data?.length > 0 && data?.data.map((centerData, idx) => (
                  <tr key={idx}>
                    <td>{centerData.rrb_name}</td>
                    {centerData.centers.map((center, centerIdx) => {
                      // Parse the date from the header (DD/MM format)
                      const [day, month] = data.header[centerIdx].split('/');
                      const targetDate = new Date(`${currentYear}-${month}-${day}`); // Construct the full date

                      // Apply the class based on date comparison and center value
                      let cellClass = '';

                      // If center is "0", apply red background
                      if (center === '0') {
                        cellClass = 'bg-danger-lt'; // Red background for "0"
                      }
                      // If date is not in the past, apply white background
                      else if (currentDate <= targetDate) {
                        cellClass = 'bg-white'; // White background for future dates
                      }
                      // If the date is in the past and center is not "0", apply green background
                      else {
                        cellClass = 'bg-success-lt text-dark'; // Green background for past dates
                      }

                      return (
                        <td key={centerIdx} className={cellClass}>
                          {center !== "0" ? center : ""}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default RRBExamSchedule;
