import { io } from "socket.io-client";

let socket; // Global socket instance
let onReportSubmittedCallback = null;

export const initializeSocket = () => {
  if (!socket) {
    console.log("App Version ===> ", process.env.REACT_APP_VERSION);
    console.log("Initializing socket...");
    const SERVER_URL =
      process.env.REACT_APP_SOCKET_URL || "http://localhost:2000";

    socket = io(SERVER_URL, { transports: ["websocket", "polling"] });

    // Handle connection
    socket.on("connect", () => {
      console.log("Socket connected with ID:", socket.id);
    });

    socket.on("report-submitted", (data) => {
      if (onReportSubmittedCallback) {
        onReportSubmittedCallback(data);
      }
    });

    // Handle disconnection
    socket.on("disconnect", () => {
      console.log("Socket disconnected");
    });
  }
  return socket;
};

export const getSocket = () => {
  if (!socket) {
    console.log("Socket not initialized! Throwing error.");
    throw new Error("Socket not initialized. Call initializeSocket first.");
  }
  return socket;
};

// Ensure socket is connected before performing any operations
export const ensureSocketConnected = async () => {
  const socket = getSocket();
  if (!socket.connected) {
    await new Promise((resolve) => socket.once("connect", resolve));
  }
  return socket;
};

export const setReportSubmittedListener = (callback) => {
  onReportSubmittedCallback = callback;
};
