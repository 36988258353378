import React, { Fragment } from "react";
import {
    RIconFile,
    RIconPaperPlane,
    RIconPC,
    RIconPerson
} from "../../components/Icons";
import { RIconRRB } from "../../components/Icons/RIconRRB";
import { StatCard } from "./StatCard";

const ControlAdminCount = ({ data = false}) => {
  if (!data) return;
  return (
    <Fragment>
      <StatCard
        icon={<RIconPerson />}
        bgClass="custom-bg-blue"
        title={data?.totalUsers}
        subtitle={"Users"}
        colSize="col-6 col-md-4 col-xxl"
      />
      <StatCard
        icon={<RIconPC />}
        bgClass="custom-bg-blue"
        title={data?.centers}
        subtitle="Centers"
        colSize="col-6 col-md-4 col-xxl"
      />
      <StatCard
        icon={<RIconFile />}
        bgClass="custom-bg-blue"
        title={data?.totalExams}
        subtitle="Exams"
        colSize="col-6 col-md-4 col-xxl"
      />
      <StatCard
        icon={<RIconPaperPlane width={30} height={28.73} />}
        bgClass="custom-bg-blue"
        title={data?.reportsSubmitted}
        subtitle="Reports Submitted"
        colSize="col-6 col-md-4 col-xxl"
      />
     
    </Fragment>
  );
};

export default ControlAdminCount;