import React from "react";
import { createImageFromInitials, getRandomColor } from "../Utils";

const Avatar = ({ name, profilePicture }) => {
  return (
    <img
      className="avatar-sm avatar-rounded"
      id="preview"
      height={36}
      src={
        profilePicture.length <= 0
          ? createImageFromInitials(500, name, getRandomColor())
          : profilePicture
      }
      alt="profile-pic"
    />
  );
};

export default Avatar;
