import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
const ReportedIncidentsChart = ({data}) => {
  const [state, setState] = useState(null)
  useEffect(() => {
    setState({
      series: [{
        name: 'Incidents',
        data: data?.data
      }],
      options: {
        chart: {
          // height:150,
          type: 'bar',
          toolbar: {
            show: true, // Enable or disable the toolbar
            tools: {
              download: false, // Disable the download option
            }
          }
        },
        plotOptions: {
          bar: {
            columnWidth: '50%', // Set bar width as a percentage of the available space (adjust this value)
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
          
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
          
        },
        xaxis: {
          categories: data?.categories,
          // position: 'top',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false,
          },
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
        colors: ['#ED1D24'],
      },
    })
  }, [data]);
  return (
    <>
      {
        state &&  <ReactApexChart options={state?.options} series={state?.series} type="bar" height={200} />
      }
      
    </>
  );
};
export default ReportedIncidentsChart;