import { Card } from "react-bootstrap";
import AnimatedComponent from "../../components/Animation/AnimatedComponent";
import Dashboard from "./Dashboard";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { IconChevronLeft } from "@tabler/icons-react";
import { PageBody } from "../../components/SiteWrapper";

const DashboardContainer = (data) => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const name = searchParams.get("name");

  return (
    <PageBody>
      <AnimatedComponent as={Card}>
        {/* <Card.Header>
          <Card.Title as={"h3"} className="text-dark card-title">
            <Link to="/recruitment_boards" title="Back">
              <IconChevronLeft className="icon text-menu me-1" />
            </Link>
            {name}
          </Card.Title>
        </Card.Header> */}
        <Card.Body className="bg-light-rrb p-0">
          <Dashboard rrb_id={id} title={name} />
        </Card.Body>
      </AnimatedComponent>
    </PageBody>
  );
};

export default DashboardContainer;
