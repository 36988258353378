import { useCallback, useEffect, useMemo, useState } from "react";
import * as UsersAPI from "../api/UsersAPI";
import { handleApiError } from "../common/errorHandler";
import { VerticalCenterModal } from "./CustomModal/VerticalCenterModal";
import DataTable from "./DataTable";
import { RrbCell, UserNameCell } from "./DataTable/TableCell";
import { PAGE_LENGTH } from "../common/constants";

export function ContactDirectory(props) {
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);

    const [filter, setFilter] = useState({
        _page: 1,
        _limit: 10,
        q: "",
        _sort: "id",
        _order: "asc",
        // status:"1"
    });
    const initialState = {
        sortBy: [{ id: "id", desc: false }],
        pageSize: filter._limit,
        pageIndex: 0,
    };
    const fetchUser = useCallback(async () => {
        setIsLoading(true);
        try {
            await UsersAPI.getContactDirectoryUsers(filter).then(({ data }) => {
                if (data?.users) {
                    setRows(data?.users);
                    setTotalRecords(data?.totalUsers);
                }
            });
        } catch (error) {
            handleApiError(error);
        } finally {
            setIsLoading(false);
        }
    }, [filter]);

    useEffect(() => {
        const getData = setTimeout(() => {
            fetchUser();
        }, 100);
        return () => clearTimeout(getData);
    }, [filter]);

    const columns = useMemo(
        () => [
            {
                Header: "RRB",
                accessor: "rrb",
                className: "text-nowrap",
                disableSortBy: true,
                width: "200",
                Cell: RrbCell,
            },
            {
                Header: "Name",
                accessor: "name",
                className: "text-nowrap w-auto",
                disableSortBy: true,
                Cell: UserNameCell,
                // width:"250",
            },
            {
                Header: "Designation",
                accessor: "designation",
                className: "text-nowrap",
                disableSortBy: true,
                width: "200",
            },
            {
                Header: "Mobile",
                accessor: "mobile",
                className: "text-nowrap",
                disableSortBy: true,
                width: "200",
                Cell: ({ row }) => (
                    <a target="_blank" href={`https://wa.me/${row.original.mobile}`} rel="noreferrer" className="text-dark text-underline">
                        {row.original.mobile}
                    </a>
                ),
            },
            {
                Header: "Role",
                accessor: "Role.name",
                className: "text-nowrap",
                disableSortBy: true,
                width: "200",
            },
        ],
        []
    );
    return (
        <VerticalCenterModal
            show={props.show}
            onHide={props.onHide}
            title="RRB Contact Directory"
            headerClass="modal-header-class"
            bodyClass="p-0"
            footerClass="modal-footer-class"
        >
                <DataTable
                cardClass="border-0 shadow-none"
                    isContactDirectory={true}
                    // header={<PageHeaderActions title="Users" actionText="Add" onClick={AddUser} />}
                    columns={columns}
                    data={rows}
                    initialState={initialState}
                    totalRecords={totalRecords}
                    setFilter={setFilter}
                    tableHooks={() => { }}
                    defaultPageLength={PAGE_LENGTH}
                    isLoading={isLoading}
                    manual={true}
                    align="table-vcenter"
                    scrollableBody={true}
                />
        </VerticalCenterModal>
    );
}
