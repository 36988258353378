import axios from "axios";
const API_PREFIX = process.env.REACT_APP_API_PREFIX;
export const getAll = async (param, extra = "") => {
  let filter =
    "?" +
    extra +
    (param
      ? Object.keys(param)
          .filter((key) => param[key] !== "")
          .map(function (key) {
            return (
              encodeURIComponent(key) + "=" + encodeURIComponent(param[key])
            );
          })
          .join("&")
      : "");
  return await axios
    .get(`${API_PREFIX}/users${filter}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const getOne = async (id) => {
  return await axios
    .get(`${API_PREFIX}/users/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const create = async (payload) => {
  return await axios
    .post(`${API_PREFIX}/users`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const update = async (id, payload) => {
  return await axios
    .put(`${API_PREFIX}/users/${id}`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const remove = async (id) => {
  return await axios
    .delete(`${API_PREFIX}/users/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};

export const getUsers = async (filter, examDate, examName,rrb_id) => {
  let DATE;

  console.log("examDate",examDate)
  if( examDate === "" && examName=== ""){
    DATE = "all_day"
  }else if(examName && examDate === undefined){
    DATE = "all_day"
  }else if(examName !== undefined && examDate !== undefined && examDate !== null){
    DATE = examDate
  }
  else if(examName !== undefined && examDate === null){
    DATE = "all_day"
  }

  return await axios
    .get(`${API_PREFIX}/users/currentexamusers?_page=${filter._page}&_limit=${filter._limit}&_sort=id&_order=asc&role=${filter.roleFilter}&q=${filter.q}&exam=${examName}&examDay=${DATE}&rrb_id_param=${rrb_id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const getContactDirectoryUsers = async (filter) => {
  return await axios
    .get(`${API_PREFIX}/users/getusers?_page=${filter._page}&_limit=${filter._limit}&_sort=id&_order=asc&q=${filter.q}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};

