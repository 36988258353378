import React, { useEffect, useState, useMemo } from "react";
import ReactApexChart from "react-apexcharts";

const CategoryWiseIncidents = ({ data, specificColor = false, total, isShowPercentage = false }) => {
  const [state, setState] = useState(null);

  const safeData = data?.data || [];

  const genericColors = useMemo(() => {
    if (!safeData.length) return [];

    const maxValue = Math.max(...safeData);
    return safeData.map((value) => {
      const baseColor = "#F44336";
      if (value === maxValue) return "#ED1D24";
      const opacity = 0.2 + (value / maxValue) * 0.8;
      return `${baseColor}${Math.round(opacity * 255)
        .toString(16)
        .padStart(2, "0")}`;
    });
  }, [safeData]);

  useEffect(() => {
    if (!safeData.length) return;

    setState({
      series: safeData,
      options: {
        chart: { type: "donut" },
        legend: { show: false },
        labels: data?.categories || [],
        dataLabels: {
          enabled: true,
          formatter: (val, opts) => {
            const value = opts.w.config.series[opts.seriesIndex];
            return isShowPercentage ? `${value}%` : value;
          },
        },
        plotOptions: {
          pie: { donut: { size: "55%" } },
        },
        colors: specificColor ? ["#2C9A32", "#FF7F3F", "#F39C12", "#ED1D24", "#A5A5A5"] : genericColors,
      },
    });
  }, [safeData, specificColor, isShowPercentage, genericColors, data?.categories]);

  return (
    <>
      {total && state ? (
        <div className="position-relative d-flex justify-content-center align-items-center">
          <span className="position-absolute fw-bold mb-3">{total}</span>
          <span className="position-absolute mt-3">Records</span>
          {state && <ReactApexChart options={state.options} series={state.series} type="donut" height={200} />}
        </div>
      ) : (
        <>{state && <ReactApexChart options={state.options} series={state.series} type="donut" height={200} />}</>
      )}
    </>
  );
};

export default CategoryWiseIncidents;
