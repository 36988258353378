import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import AnimatedComponent from "../../components/Animation/AnimatedComponent";
import StatusCapsual from "../../components/DataTable/StatusCapsule";
import { Link } from "react-router-dom";
import DateCard from "../ExamReport/DateCard";

export const UpcomingExam = ({ data = [] }) => {
  if (!data || data.length === 0) return;
  return (
    <Col md="12">
      <h2 className="page-title mb-2">Upcoming Exams</h2>
      {data?.map((exam, examInd) => (
        <AnimatedComponent as={Card} className="mt-3" key={examInd}>
          <Card.Body className="p-3">
            <div className=" d-flex justify-content-between">
              <div>
                <h3 className="mb-0 fs-18 fw-medium">{exam?.exam_name}</h3>
                <Card.Text className="text-secondary mb-2">
                  {exam?.post?.description} ({exam?.post?.name})
                </Card.Text>
                <StatusCapsual status={exam?.status} />
              </div>
              <div>
                <Link
                  to={`/exams/edit/${exam?.id}`}
                  className="btn btn-primary"
                >
                  Exam Setup
                </Link>
              </div>
            </div>
          </Card.Body>
          <Card.Body className="p-3">
            <Row className="g-3">
              {exam?.examDates?.map((exmDay, dayInd) => (
                <DateCard exam={exmDay} key={dayInd} />
              ))}
            </Row>
          </Card.Body>
        </AnimatedComponent>
      ))}
    </Col>
  );
};
